<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>营销推广</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div>
                <VyAddBtn @click.native="openAdd" />
                <!-- <button @click="openAdd">添加</button> -->
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="title" align="center" label="名称"> </el-table-column>
                <el-table-column prop="url" align="center" label="链接"> </el-table-column>
                <el-table-column prop="icon" align="center" label="图标">
                    <template slot-scope="{ row }">
                        <img :src="row.icon" style="width: 100%" />
                    </template>
                </el-table-column>
                <el-table-column prop="icon_status" align="center" label="图标状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.icon_status ? '#b66dff' : '#fe7c96' }">{{
                            row.icon_status ? "显示" : "隐藏"
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="btns">
                            <div class="_operate">
                                <button @click="openSet(row)">编辑</button>
                            </div>
                            <div class="_delete">
                                <button @click="del(row)">删除</button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 添加或编辑 -->
        <el-dialog
            :title="`${form.id ? '编辑' : '添加'}`"
            :visible.sync="dialogShow"
            width="50%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form" label-width="80px">
                <el-form-item label="名称">
                    <el-input v-model.trim="form.title"></el-input>
                </el-form-item>
                <el-form-item label="链接">
                    <el-input v-model.trim="form.url"></el-input>
                </el-form-item>
                <el-form-item label="图标">
                    <upload-img :src="form.icon" @removeSrc="() => (form.icon = '')" ref="icon"></upload-img>
                </el-form-item>
                <el-form-item label="图标状态">
                    <el-switch
                        class="sw"
                        v-model="form.icon_status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="开启"
                        inactive-text="关闭"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
import uploadImg from "@/components/customs/uploadImg.vue";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";
export default {
    components: { uploadImg, VyAddBtn },
    data() {
        return {
            tableData: [],
            form: {},
            dialogShow: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/marketing/show");
            return data;
        },
        // 打开添加
        openAdd() {
            this.form = {
                title: "",
                url: "",
                intro: "",
                icon: "",
                icon_status: 1,
            };
            this.dialogShow = true;
        },
        // 打开编辑
        openSet(row) {
            this.form = { ...row };
            this.dialogShow = true;
        },
        async yes() {
            let form = { ...this.form };
            form.icon = this.$refs.icon.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/marketing/edit", QueryString.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/marketing/add", QueryString.stringify(form));
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
                return;
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => true);
            if (!s) return;
            let { data } = await axios.delete("/api/marketing/del", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
